






import { Component, Prop, Vue } from 'vue-property-decorator';
import { Input } from 'ant-design-vue';

Vue.use(Input);

@Component
export default class Test extends Vue {
  @Prop() private msg!: string;
}
